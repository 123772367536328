<template>
  <div class="animated fadeIn tender-page">
    <h4
      v-if="data.status === 0"
      class="tender-page__title"
    >
      Тендер <span v-if="data.name">{{ data.name }}</span>
      <span
        class="text-primary"
      > (Черновик)</span>
    </h4>
    <div
      v-if="form.organizations.value"
      class="custom-fieldset"
    >
      <h4>Организация - {{ form.organizations.value }}</h4>
    </div>
    <div
      v-if="data.status === 4"
      class="custom-fieldset"
    >
      <div class="tender-info">
        <div
          class="tender-info__last-bet"
        >
          <p class="tender-info__last-bet-title">
            <b>Подтверждение победителя</b>
          </p>
          <p class="tender-info__last-bet-desc">
            Система определила победителя: {{ data.activeOffer.contractor.name }} — {{ numberRUFormat(data.activeOffer.sum) }} ₽
            <span v-if="form.VAT.value">
              (С НДС)
            </span>
            <span v-else>
              (Без НДС)
            </span>
            <span
              v-if="data.alternativeAmounts"
              class="tender-info__item-desc-note"
            >
              {{ alternativeAmountValue(form.VAT.value, data.activeOffer.sum) }}
            </span>
          </p>
          <v-client-table
            id="dataTable"
            :data="form.routeWays"
            :columns="routesBriefInformation.columns"
            :options="routesBriefInformation.options"
            :theme="routesBriefInformation.theme"
          >
            <span
              slot="winTemplateSumm"
              slot-scope="props"
            >
              {{ numberRUFormat(props.row.winTemplateSumm) }} руб.
            </span>
            <span
              slot="winRouteSumm"
              slot-scope="props"
            >
              {{ numberRUFormat(props.row.winRouteSumm) }} руб.
            </span>
          </v-client-table>
        </div>
      </div>
    </div>
    <div
      v-else-if="data.status === 6 || data.status === 7"
      class="custom-fieldset"
    >
      <div class="tender-info">
        <div
          class="tender-info__last-bet"
        >
          <p class="tender-info__last-bet-title">
            <b>Тендер завершен</b>
          </p>
          <p class="tender-info__last-bet-desc">
            Победитель: {{ data.activeOffer.contractor.name }} — {{ numberRUFormat(data.activeOffer.sum) }} ₽
            <span v-if="form.VAT.value">
              (С НДС)
            </span>
            <span v-else>
              (Без НДС)
            </span>
            <span
              v-if="data.alternativeAmounts"
              class="tender-info__item-desc-note"
            >
              {{ alternativeAmountValue(form.VAT.value, data.activeOffer.sum) }}
            </span>
          </p>
          <v-client-table
            id="dataTable"
            :data="form.routeWays"
            :columns="routesBriefInformation.columns"
            :options="routesBriefInformation.options"
            :theme="routesBriefInformation.theme"
          >
            <span
              slot="winTemplateSumm"
              slot-scope="props"
            >
              {{ numberRUFormat(props.row.winTemplateSumm) }} руб.
            </span>
            <span
              slot="winRouteSumm"
              slot-scope="props"
            >
              {{ numberRUFormat(props.row.winRouteSumm) }} руб.
            </span>
          </v-client-table>
        </div>
      </div>
    </div>
    <div
      v-if="data.status !== 0"
      class="tender-info"
    >
      <div
        class="tender-info__body"
      >
        <h1
          class="tender-info__body-title"
        >
          Тендер <span v-if="data.name">{{ data.name }}</span>
          <span
            v-if="data.status === 1"
            class="tender-info__body-title-status bg-primary"
          >Ожидает начала</span>
          <span
            v-else-if="data.status === 2"
            class="tender-info__body-title-status bg-success"
          >Прием предложений</span>
          <span
            v-else-if="data.status === 3"
            class="tender-info__body-title-status bg-warning"
          >Нет победителя</span>
          <span
            v-else-if="data.status === 4"
            class="tender-info__body-title-status bg-danger"
          >Завершен</span>
          <span
            v-else-if="data.status === 5"
            class="tender-info__body-title-status bg-danger"
          >Отменен</span>
          <span
            v-else-if="data.status === 6"
            class="tender-info__body-title-status bg-secondary"
          >Утвержден</span>
          <span
            v-else-if="data.status === 7"
            class="tender-info__body-title-status bg-warning"
          >Ожидает генерации рейсов</span>
        </h1>
        <b-row>
          <b-col md="4">
            <div class="tender-info__nds">
              <p class="tender-info__nds-title">
                Данный тендер имеет ценообразование —
                <b v-if="form.VAT.value"> с НДС</b>
                <b v-else> без НДС</b>
              </p>
            </div>
            <div class="tender-info__item">
              <p class="tender-info__item-title">
                Стартовая стоимость тендера
              </p>
              <p class="tender-info__item-desc">
                {{ numberRUFormat(data.totalPrice) }} ₽
                <span v-if="form.VAT.value">
                  (С НДС)
                </span>
                <span v-else>
                  (Без НДС)
                </span>
                <span
                  v-if="data.alternativeAmounts"
                  class="tender-info__item-desc-note"
                >
                  {{ alternativeAmountValue(form.VAT.value, data.totalPrice) }}
                </span>
              </p>
            </div>
            <div
              v-if="data.status === 6 || data.status === 7"
              class="tender-info__item"
            >
              <p class="tender-info__item-title">
                Финальная стоимость тендера
              </p>
              <p class="tender-info__item-desc">
                {{ numberRUFormat(data.activeOffer.sum) }} ₽
                <span v-if="form.VAT.value">
                  (С НДС)
                </span>
                <span v-else>
                  (Без НДС)
                </span>
                <span
                  v-if="data.alternativeAmounts"
                  class="tender-info__item-desc-note"
                >
                  {{ alternativeAmountValue(form.VAT.value, data.activeOffer.sum) }}
                </span>
              </p>
            </div>
          </b-col>
          <b-col md="4">
            <div class="tender-info__item">
              <p
                v-if="data.status === 1"
                class="tender-info__item-title"
              >
                До начала осталось
              </p>
              <p
                v-else
                class="tender-info__item-title"
              >
                До окончания осталось
              </p>
              <p
                v-if="data.status === 3 || data.status === 4 || data.status === 6 || data.status === 7"
                class="tender-info__item-desc"
              >
                Тендер завершен
              </p>
              <p
                v-else
                class="tender-info__item-desc"
              >
                {{ data.countdown }}
              </p>
            </div>
            <div class="tender-info__item">
              <p class="tender-info__item-title">
                Дата начала
              </p>
              <p class="tender-info__item-desc">
                <i class="fa fa-clock-o fa-sm mr-3" />{{ formatDatetime(data.startDate) }}
              </p>
            </div>
            <div class="tender-info__item">
              <p class="tender-info__item-title">
                Дата окончания
              </p>
              <p class="tender-info__item-desc">
                <i class="fa fa-clock-o fa-sm mr-3" />{{ formatDatetime(data.endDate) }}
              </p>
            </div>
          </b-col>
          <b-col md="4">
            <div class="tender-info__item">
              <p class="tender-info__item-title">
                Всего маршрутов
              </p>
              <p class="tender-info__item-desc">
                {{ form.routeWays.length }}
              </p>
            </div>

          </b-col>
        </b-row>
      </div>
    </div>
    <div
      v-if="data.status === 3 && possibleWinners.items.length > 0"
      class="custom-fieldset"
    >
      <div class="tender-info">
        <div
          class="tender-info__last-bet"
        >
          <p class="tender-info__last-bet-title">
            Возможные победители
          </p>
          <v-client-table
            id="dataTable"
            :data="possibleWinners.items"
            :columns="possibleWinners.columns"
            :options="possibleWinners.options"
            :theme="possibleWinners.theme"
          >
            <span
              slot="sum"
              slot-scope="props"
            >{{ numberRUFormat(props.row.sum) }} руб. {{ alternativeAmountValue(form.VAT.value, props.row.sum) }}</span>
            <span
              slot="actions"
              slot-scope="props"
            >
              <b-button
                variant="success"
                size="sm"
                :disabled="loading"
                :class="{'loading' : loading}"
                @click="setWinner(props.row.id)"
              >Назначить победителем</b-button>
            </span>
          </v-client-table>
        </div>
        <div
          v-if="data.status === 6 || data.status === 7"
          class="tender-info__last-bet"
        >
          <p class="tender-info__last-bet-title">
            Тендер
          </p>
          <p class="tender-info__last-bet-time text-secondary">
            <i class="fa fa-clock-o fa-sm mr-2" />{{ formatDatetime(data.endDate) }}<br>(Завершен)
          </p>
          <div class="tender-info__last-bet-current-bid">
            <span class="tender-info__last-bet-current-bid-title">Финальное предложение</span>
            <span class="tender-info__last-bet-current-bid-value">
              {{ numberRUFormat(data.activeOffer.summ) }}<i class="fa fa-ruble fa-sm ml-2 mr-2" />
              <span class="tender-info__last-bet-current-bid-value-vat">{{ alternativeAmountValue(form.VAT.value, data.activeOffer.summ) }}</span>
            </span>
          </div>
          <div class="tender-info__last-bet-current-contractor">
            <span class="tender-info__last-bet-current-contractor-title">Перевозчик</span>
            <span class="tender-info__last-bet-current-contractor-value">{{ data.activeOffer.contractor.name }}</span>
          </div>
          <div class="tender-info__last-bet-current-time">
            <span class="tender-info__last-bet-current-time-title">Время подачи предложения</span>
            <span class="tender-info__last-bet-current-time-value">{{ formatDatetime(data.activeOffer.updatedOn) }}</span>
          </div>
          <p class="tender-info__last-bet-desc">
            Тендер утвержден, рейсы взяты в обработку.
          </p>
        </div>
      </div>
    </div>
    <div
      v-if="data.status === 2 || data.status === 3 || data.status === 4 || data.status === 5"
      class="custom-fieldset"
    >
      <div class="tender-info">
        <div
          class="tender-info__last-bet"
        >
          <p class="tender-info__last-bet-title">
            Участники
          </p>
          <p
            v-if="tenderContractorProposals.items.length === 0"
            class="tender-info__last-bet-desc"
          >
            Никто из участников не сделал ни одной ставки/предложения.
          </p>
          <v-client-table
            v-if="tenderContractorProposals.items.length > 0"
            id="dataTable"
            :data="tenderContractorProposals.items"
            :columns="tenderContractorProposals.columns"
            :options="tenderContractorProposals.options"
            :theme="tenderContractorProposals.theme"
          >
            <span
              slot="sum"
              slot-scope="props"
            >
              {{ numberRUFormat(props.row.sum) }} руб.
              <span
                v-if="data.alternativeAmounts"
                class="tender-info__item-desc-note"
              >
                {{ alternativeAmountValue(form.VAT.value, props.row.sum) }}
              </span>
            </span>
            <span
              slot="contractor.customers.0.rating.rating"
              slot-scope="props"
            >
              <span
                v-if="props.row.contractor.customers && props.row.contractor.customers.length > 0 && props.row.contractor.customers[0].rating"
              >
                <span
                  :class="{'rating-low': props.row.contractor.customers[0].rating.rating <= 30, 'rating-mid': props.row.contractor.customers[0].rating.rating >= 31 && props.row.contractor.customers[0].rating.rating <= 49, 'rating-high': props.row.contractor.customers[0].rating.rating >= 50}"
                >{{ props.row.contractor.customers[0].rating.rating }}</span>
              </span>
            </span>
            <span
              slot="actions"
              slot-scope="props"
            >
              <b-button
                variant="success"
                size="sm"
                :disabled="loading"
                :class="{'loading' : loading}"
                @click="setWinner(props.row.contractor.id)"
              >Назначить победителем</b-button>
            </span>
          </v-client-table>
        </div>
        <div
          v-if="data.status === 6 || data.status === 7"
          class="tender-info__last-bet"
        >
          <p class="tender-info__last-bet-title">
            Тендер
          </p>
          <p class="tender-info__last-bet-time text-secondary">
            <i class="fa fa-clock-o fa-sm mr-2" />{{ formatDatetime(data.endDate) }}<br>(Завершен)
          </p>
          <div class="tender-info__last-bet-current-bid">
            <span class="tender-info__last-bet-current-bid-title">Финальное предложение</span>
            <span class="tender-info__last-bet-current-bid-value">
              {{ numberRUFormat(data.activeOffer.summ) }}<i class="fa fa-ruble fa-sm ml-2 mr-2" />
              <span class="tender-info__last-bet-current-bid-value-vat">{{ alternativeAmountValue(form.VAT.value, data.activeOffer.summ) }}</span>
            </span>
          </div>
          <div class="tender-info__last-bet-current-contractor">
            <span class="tender-info__last-bet-current-contractor-title">Перевозчик</span>
            <span class="tender-info__last-bet-current-contractor-value">{{ data.activeOffer.contractor.name }}</span>
          </div>
          <div class="tender-info__last-bet-current-time">
            <span class="tender-info__last-bet-current-time-title">Время подачи предложения</span>
            <span class="tender-info__last-bet-current-time-value">{{ formatDatetime(data.activeOffer.updatedOn) }}</span>
          </div>
          <p class="tender-info__last-bet-desc">
            Тендер утвержден, рейсы взяты в обработку.
          </p>
        </div>
      </div>
    </div>
    <div class="custom-fieldset">
      <tender-flight-schedule
        v-show="data.status != 6"
        :key="form.routeWay"
        :route-ways="form.routeWays"
      />
    </div>
    <div>
      <div class="tender-info__item">
              <p class="tender-info__item-title">
                Стоимость заезда в доп. точку
              </p>
              <p class="tender-info__item-desc">
                {{ numberRUFormat(data.additionalWayPointPrice) }} ₽
                <span v-if="form.VAT.value">
                  (С НДС)
                </span>
                <span v-else>
                  (Без НДС)
                </span>
                <span
                  class="tender-info__item-desc-note"
                >
                  {{ alternativeAmountValue(form.VAT.value, data.additionalWayPointPrice) }}
                </span>
              </p>
      </div>
    </div>
    <div>
      <div class="form-sections">
        <div
          v-for="(routeWay, index) in form.routeWays"
          :key="`route-way-${index}`"
          class="custom-fieldset"
        >
          <h5 class="custom-fieldset__title">
            <i class="fa fa-map-o fa-lg mr-2" />Маршрут и расписание №{{ index + 1 }}
          </h5>
          <div class="route-and-flight-details">
            <div class="route-and-flight-details__route-container">
              <h6 class="route-and-flight-details__route-container-title route-and-flight-details__title custom-fieldset__title">
                Маршрут тендера:
              </h6>
              <b-row>
                <b-col md="4">
                  <b-form-group
                    class="form-group-el-select"
                    label="Код маршрута"
                  >
                    <p>{{ routeWay.routeWayCode }}</p>
                    <small
                      v-if="data.routeWays[index].fullDistance"
                      class="form-text text-muted"
                    >Расстояние: {{ data.routeWays[index].fullDistance }} км.</small>
                    <small
                      v-else
                      class="form-text text-muted"
                    >Формат заполнения ячейки - МВМСЧБ</small>
                    <small
                    v-if="data.routeWays[index].timeSummer"
                    class="form-text text-muted"
                    >
                    Ориентировочное время: {{ data.routeWays[index].timeSummer }}
                    </small>
                    <small v-if="
												data.routeWays[index].timeSummer
											" class="text-muted">
											<strong>*</strong> Точное время доставки будет указано в транспортной накладной после отправки.</small>
                  </b-form-group>
                  <b-form-group
                    v-if="routeWay.transportationName"
                    class="form-group-el-select"
                    label="Тип перевозки"
                  >
                    <p>{{ routeWay.transportationName }}</p>
                  </b-form-group>
                  <div v-if="data.routeWays[index].points.length > 0">
                    <b>Точки маршрута:</b>
                    <ol class="pl-0 list-inside">
                      <li
                        v-for="point in data.routeWays[index].points"
                        :key="`${index}-route-way-point-${point.id}`"
                      >
                        {{ point.department.mainWarehouse.address }}
                      </li>
                    </ol>
                    <b>Вид перевозки:</b>
                    <p class="mb-3">
                      {{ data.routeWays[index].transportationType.name }}
                      <i
                        v-if="data.routeWays[index].transportationType.id == 1"
                        class="fa fa-plane fa-md"
                      />
                      <i
                        v-if="data.routeWays[index].transportationType.id == 2"
                        class="fa fa-truck fa-md"
                      />
                      <i
                        v-if="data.routeWays[index].transportationType.id == 3"
                        class="fa fa-subway fa-md"
                      />
                      <i
                        v-if="data.routeWays[index].transportationType.id == 4"
                        class="fa fa-ban fa-md"
                      />
                    </p>
                  </div>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    id="usernameInputGroup1"
                    label="Стоимость для одного рейса по маршруту"
                    label-for="name"
                    description="Формат заполнения ячейки - 10000. Валюта - рубли."
                  >
                    <p v-if="data.status !== 6">{{ numberRUFormat(routeWay.routePrice) }} руб.</p>
                    <p v-else>{{ numberRUFormat(routeWay.winRouteSumm) }} руб.</p>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    id="usernameInputGroup2"
                    label="Шаг ставки"
                    label-for="name"
                    description="Формат заполнения ячейки - 100. Валюта - рубли."
                  >
                    <p>{{ numberRUFormat(routeWay.stepRate) }} руб.</p>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Получать стоимость тендера автоматически">
                    <b-form-checkbox
                      v-model="routeWay.autoTariff"
                      name="autoTariff"
                      disabled
                    >
                      Да
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col md="4">
									<b-form-group
								        label="Вид маршрута"
									>
										<b-form-radio-group v-model="routeWay.routeType" disabled>
											<b-form-radio
												v-model="routeWay.routeType"
												name="routeType"
												value="oneWay"
											>
												В один конец
											</b-form-radio>
											<b-form-radio
												v-model="routeWay.routeType"
												name="routeType"
												value="circular"
											>
												Круговой
											</b-form-radio>
										</b-form-radio-group>
									</b-form-group>
								</b-col>
              </b-row>
            </div>
            <div class="route-and-flight-details__transport">
              <h6 class="route-and-flight-details__title custom-fieldset__title">
                Транспорт
              </h6>
              <b-row>
                <b-col md="4">
                  <b-form-group
                    class="route-and-flight-details__form-group form-group-el-select"
                    label="Габариты"
                  >
                    <dimensions-view
                      v-if="data.status !== 6"
                      v-model="routeWay.dimensions"
                      :price="routeWay.routePrice"
                    />
                    <dimensions-view
                      v-else
                      v-model="routeWay.dimensions"
                      :price="routeWay.winRouteSumm"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    class="route-and-flight-details__form-group form-group-el-select"
                    label="Погрузка/Выгрузка"
                  >
                    <b-form-checkbox-group
                      v-model="routeWay.loadingUnloading.value"
                      name="loading-unloading"
                      stacked
                    >
                      <b-form-checkbox
                        v-for="(item, optionIndex) in form.loadingUnloading.options"
                        :key="optionIndex"
                        :value="item.value"
                        disabled
                      >
                        {{ item.text }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    class="route-and-flight-details__form-group form-group-el-select"
                    label="Тип кузова"
                  >
                    <b-form-checkbox-group
                      v-model="routeWay.bodyType.value"
                      name="body-type"
                      stacked
                    >
                      <b-form-checkbox
                        v-for="(item, optionIndex) in form.bodyType.options"
                        :key="optionIndex"
                        :value="item.value"
                        disabled
                      >
                        {{ item.text }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div class="route-and-flight-details__route-container">
              <h6 class="route-and-flight-details__route-container-title route-and-flight-details__title custom-fieldset__title">
                Диапазон перевозок:
              </h6>
              <b-row>
                <b-col md="4">
                  <b-form-group
                    label="Дата начала маршрута"
                  >
                    <el-date-picker-input
                      v-model="routeWay.startDate"
                      type="date"
                      format="dd.MM.yyyy"
                      placeholder="дд.мм.гггг"
                      :picker-options="pickerOptions"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Дата окончания маршрута"
                  >
                    <el-date-picker-input
                      v-model="routeWay.stopDate"
                      type="date"
                      format="dd.MM.yyyy"
                      placeholder="дд.мм.гггг"
                      :picker-options="pickerOptions"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <h6 class="custom-fieldset__subtitle">
                    Количество недель
                  </h6>
                  <p>{{ data.routeWays[index].weekCount }}</p>
                </b-col>
              </b-row>
              <h6 class="route-and-flight-details__route-container-title route-and-flight-details__title custom-fieldset__title">
                Расписание на неделю
              </h6>
              <div class="route-way-week">
                <div class="route-way-week__head">
                  <div
                    :class="{'active' : routeWay.weekTemplate.monday.length > 0}"
                    class="route-way-week__head-item"
                  >
                    ПН
                  </div>
                  <div
                    :class="{'active' : routeWay.weekTemplate.tuesday.length > 0}"
                    class="route-way-week__head-item"
                  >
                    ВТ
                  </div>
                  <div
                    :class="{'active' : routeWay.weekTemplate.wednesday.length > 0}"
                    class="route-way-week__head-item"
                  >
                    СР
                  </div>
                  <div
                    :class="{'active' : routeWay.weekTemplate.thursday.length > 0}"
                    class="route-way-week__head-item"
                  >
                    ЧТ
                  </div>
                  <div
                    :class="{'active' : routeWay.weekTemplate.friday.length > 0}"
                    class="route-way-week__head-item"
                  >
                    ПТ
                  </div>
                  <div
                    :class="{'active' : routeWay.weekTemplate.saturday.length > 0}"
                    class="route-way-week__head-item"
                  >
                    СБ
                  </div>
                  <div
                    :class="{'active' : routeWay.weekTemplate.sunday.length > 0}"
                    class="route-way-week__head-item"
                  >
                    ВС
                  </div>
                </div>
                <div class="route-way-week__body">
                  <div class="route-way-week__body-weekday">
                    <label
                      v-for="(item, weekItemIndex) in routeWay.weekTemplate.monday"
                      :key="`route-way-week-item-${weekItemIndex}`"
                      class="route-way-week__body-item"
                    >
                      <b-form-input
                        v-model="item.time"
                        autofocus
                        type="time"
                        placeholder="00:00"
                        class="route-way-week__body-time"
                        disabled
                      />
                    </label>
                  </div>
                  <div class="route-way-week__body-weekday">
                    <label
                      v-for="(item, weekItemIndex) in routeWay.weekTemplate.tuesday"
                      :key="`route-way-week-item-${weekItemIndex}`"
                      class="route-way-week__body-item"
                    >
                      <b-form-input
                        v-model="item.time"
                        autofocus
                        type="time"
                        placeholder="00:00"
                        class="route-way-week__body-time"
                        disabled
                      />
                    </label>
                  </div>
                  <div class="route-way-week__body-weekday">
                    <label
                      v-for="(item, weekItemIndex) in routeWay.weekTemplate.wednesday"
                      :key="`route-way-week-item-${weekItemIndex}`"
                      class="route-way-week__body-item"
                    >
                      <b-form-input
                        v-model="item.time"
                        autofocus
                        type="time"
                        placeholder="00:00"
                        class="route-way-week__body-time"
                        disabled
                      />
                    </label>
                  </div>
                  <div class="route-way-week__body-weekday">
                    <label
                      v-for="(item, weekItemIndex) in routeWay.weekTemplate.thursday"
                      :key="`route-way-week-item-${weekItemIndex}`"
                      class="route-way-week__body-item"
                    >
                      <b-form-input
                        v-model="item.time"
                        autofocus
                        type="time"
                        placeholder="00:00"
                        class="route-way-week__body-time"
                        disabled
                      />
                    </label>
                  </div>
                  <div class="route-way-week__body-weekday">
                    <label
                      v-for="(item, weekItemIndex) in routeWay.weekTemplate.friday"
                      :key="`route-way-week-item-${weekItemIndex}`"
                      class="route-way-week__body-item"
                    >
                      <b-form-input
                        v-model="item.time"
                        autofocus
                        type="time"
                        placeholder="00:00"
                        class="route-way-week__body-time"
                        disabled
                      />
                    </label>
                  </div>
                  <div class="route-way-week__body-weekday">
                    <label
                      v-for="(item, weekItemIndex) in routeWay.weekTemplate.saturday"
                      :key="`route-way-week-item-${weekItemIndex}`"
                      class="route-way-week__body-item"
                    >
                      <b-form-input
                        v-model="item.time"
                        autofocus
                        type="time"
                        placeholder="00:00"
                        class="route-way-week__body-time"
                        disabled
                      />
                    </label>
                  </div>
                  <div class="route-way-week__body-weekday">
                    <label
                      v-for="(item, weekItemIndex) in routeWay.weekTemplate.sunday"
                      :key="`route-way-week-item-${weekItemIndex}`"
                      class="route-way-week__body-item"
                    >
                      <b-form-input
                        v-model="item.time"
                        autofocus
                        type="time"
                        placeholder="00:00"
                        class="route-way-week__body-time"
                        disabled
                      />
                    </label>
                  </div>
                </div>
              </div>
              <p>Заданная конфигурация будет применима ко всем неделям в рамках диапазона перевозок</p>
              <p v-if="data.routeWays[index].routeCount">
                Всего рейсов по маршруту - {{ data.routeWays[index].routeCount }}
              </p>
              <div v-if="form.routeWays[index].note.value && form.routeWays[index].note.value !== undefined">
          <div class="mt-3 mb-4">
          <b-row>
            <b-col md="4">
              <p class="main-info__item-title">Комментарий к маршруту тендера</p>
              <p class="main-info__item-desc">
                {{form.routeWays[index].note.value}}
              </p>
            </b-col>
          </b-row>								
        </div>
        </div>
            </div>
          </div>
        </div>
        <div class="custom-fieldset">
          <h5 class="custom-fieldset__title">
            <i class="fa fa-gavel fa-lg mr-2" />Тендер
          </h5>
          <b-tabs>
            <b-tab
              class="terms-and-participants"
              title="Условия и участники"
            >
              <b-row no-gutters>
                <b-col lg="8">
                  <div class="terms-and-participants__contractors">
                    <h6 class="terms-and-participants__title">
                      Участвующие перевозчики
                    </h6>
                    <p>Список участников сформирован на стадии черновика тендера</p>
                    <v-client-table
                      id="dataTable"
                      :data="contractors.items"
                      :columns="contractors.columns"
                      :options="contractors.options"
                      :theme="contractors.theme"
                    >
                      <span
                        slot="customers.0.rating.rating"
                        slot-scope="props"
                      >
                        <span
                          v-if="props.row.customers && props.row.customers.length > 0 && props.row.customers[0].rating"
                        >
                          <span
                            :class="{'rating-low': props.row.customers[0].rating.rating <= 30, 'rating-mid': props.row.customers[0].rating.rating >= 31 && props.row.customers[0].rating.rating <= 49, 'rating-high': props.row.customers[0].rating.rating >= 50}"
                          >{{ props.row.customers[0].rating.rating }}</span>
                        </span>
                      </span>
                    </v-client-table>
                  </div>
                </b-col>
                <b-col lg="4">
                  <div class="terms-and-participants__start-price">
                    <h6 class="terms-and-participants__title">
                      Первоначальная стоимость лота
                    </h6>
                    <p>Сформирована из всех добавленных маршрутов</p>
                    <b-row>
                      <b-col
                        xl="6"
                        lg="12"
                        md="6"
                      >
                        <b-form-group>
                          <h5>{{ numberRUFormat(data.totalPrice) }} руб.</h5>
                        </b-form-group>
                      </b-col>
                      <b-col
                        xl="6"
                        lg="12"
                        md="6"
                      >
                        <b-form-group
                          class="form-group-el-select"
                          :description="alternativeAmountValue(form.VAT.value, data.totalPrice)"
                        >
                          <h5><u>{{ form.VAT.options[form.VAT.value].text }}</u></h5>
                          <!-- <el-select
                            v-model="form.VAT.value"
                            class="form-control"
                            name="route-way"
                            :loading="loading"
                            disabled
                          >
                            <el-option
                              v-for="item in form.VAT.options"
                              :key="item.value"
                              :label="item.text"
                              :value="item.value"
                            />
                          </el-select> -->
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="terms-and-participants__tender-duration">
                      <h6 class="terms-and-participants__title">
                        Период проведения тендера
                      </h6>
                      <p>Период проведения тендера назначается непосредственно перед запуском. Для указания времени воспользуйтесь кнопкой "Сохранить и запустить тендер"</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab title="История">
              <div class="history-tender">
                <h6 class="history-tender__title">
                  События
                </h6>
                <p class="history-tender__desc">
                  Список событий сформированный на протяжении всего существования тендера
                </p>
                <ul class="history-tender__list">
                  <li
                    v-for="item in data.history"
                    :key="'history-item-' + item.id"
                    class="history-tender__list-item"
                  >
                    <span
                      v-if="item.type === 'tenderCreate'"
                      class="text-success"
                    >
                      <span class="history-tender__list-item-date">
                        {{ formatDatetime(item.updatedOn) }}
                        <span v-if="item.manager && item.manager.fullname">({{ item.manager.fullname }})</span>
                        <span v-else-if="item.manager">({{ item.manager.username }})</span>
                      </span>
                      Тендер создан</span>
                    <span
                      v-else-if="item.type === 'tenderUpdate'"
                      class="text-primary"
                    >
                      <span class="history-tender__list-item-date">
                        {{ formatDatetime(item.updatedOn) }}
                        <span v-if="item.manager && item.manager.fullname">({{ item.manager.fullname }})</span>
                        <span v-else-if="item.manager">({{ item.manager.username }})</span>
                      </span>
                      Тендер обновлен:
                      <ol
                        v-if="item.data"
                        class="history-tender__list-item-changes"
                      >
                        <li v-if="item.data.status">Тендер переведен в статус
                          "<span v-if="item.data.status[1] === 0">Черновик</span>
                          <span v-else-if="item.data.status[1] === 1">Ожидает начала</span>
                          <span v-else-if="item.data.status[1] === 2">Прием предложений</span>
                          <span v-else-if="item.data.status[1] === 3">Нет победителя</span>
                          <span v-else-if="item.data.status[1] === 4">Есть победитель</span>
                          <span v-else-if="item.data.status[1] === 5">Отменен</span>
                          <span v-else-if="item.data.status[1] === 6">Утвержден</span>
                          <span v-else-if="item.data.status[1] === 7">Ожидает генерации рейсов</span>"
                        </li>
                        <li v-if="item.data.startDate">Дата начала тендера изменена на "{{ formatDatetime(item.data.startDate[1]) }}"</li>
                        <li v-if="item.data.withNDS">Значение НДС изменено на "{{ item.data.withNDS[1] ? 'С НДС' : 'Без НДС' }}"</li>
                        <li v-if="item.data.startPrice">Значение начальной стоимости изменено на "{{ item.data.startPrice[1] }} руб."</li>
                        <li v-if="item.data.step">Значение шага изменено на "{{ item.data.step[1] }} руб."</li>
                        <li v-if="item.data.dimension">
                          <span v-if="item.data.dimension[0]">Значение габаритов очищено</span>
                          <span v-else>Значение габаритов изменено на "{{ item.data.dimension[1].name }}"</span>
                        </li>
                        <li v-if="item.data.loadingTypes">Значение погрузки/выгрузки изменено на "{{ item.data.loadingTypes[1].name }}"</li>
                        <li v-if="item.data.bodyTypes">Значение типов кузовов изменено</li>
                        <!--li v-if="item.data.roundOffersCount">Значение типов кузовов изменено на "{{ item.data.roundOffersCount[1].name }}"</li-->
                      </ol>
                    </span>
                    <span
                      v-else-if="item.type === 'changeLoadingTypes'"
                      class="text-primary"
                    >
                      <span class="history-tender__list-item-date">
                        {{ formatDatetime(item.updatedOn) }}
                        <span v-if="item.manager && item.manager.fullname">({{ item.manager.fullname }})</span>
                        <span v-else-if="item.manager">({{ item.manager.username }})</span>
                      </span>
                      Тендер обновлен:
                      <ol class="history-tender__list-item-changes">
                        <li v-if="item.data">Значение погрузки/выгрузки изменено на: "<span
                          v-for="(loadingType, index) in item.data"
                          :key="'loading-type-' + loadingType.id"
                        >{{ loadingType.name }}{{ Number(index) !== item.data.length - 1 ? ', ' : '' }}</span>"</li>
                      </ol>
                    </span>
                    <span
                      v-else-if="item.type === 'changeVehicleBodyTypes'"
                      class="text-primary"
                    >
                      <span class="history-tender__list-item-date">
                        {{ formatDatetime(item.updatedOn) }}
                        <span v-if="item.manager && item.manager.fullname">({{ item.manager.fullname }})</span>
                        <span v-else-if="item.manager">({{ item.manager.username }})</span>
                      </span>
                      Тендер обновлен:
                      <ol
                        v-if="item.data"
                        class="history-tender__list-item-changes"
                      >
                        <li v-if="item.data">Значение типов кузовов изменено на: "<span
                          v-for="(bodyType, index) in item.data"
                          :key="'body-type-' + bodyType.id"
                        >{{ bodyType.name }}{{ Number(index) !== item.data.length - 1 ? ', ' : '' }}</span>"</li>
                      </ol>
                    </span>
                    <span
                      v-else-if="item.type === 'newOffer'"
                      class="text-primary"
                    >
                      <span class="history-tender__list-item-date">
                        {{ formatDatetime(item.updatedOn) }}
                        <span v-if="item.manager && item.manager.fullname">({{ item.manager.fullname }})</span>
                        <span v-else-if="item.manager">({{ item.manager.username }})</span>
                      </span>
                      Поступило предложение:<br>
                      "{{ item.data.contractor.name }}" отправил предложение в размере {{ numberRUFormat(item.data.summ) }} руб. {{ alternativeAmountValue(form.VAT.value, item.data.summ) }}
                    </span>
                  </li>
                </ul>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
      <div class="fixed-buttons">
        <b-button
          type="button"
          variant="secondary"
          class="mr-2"
          :class="{'loading' : loading}"
          @click="$router.go(-1)"
        >
          Назад
        </b-button>
        <a
          v-if="$store.state.user.ownerType === 'customer' && data.status === 0"
          :href="`/tenders/customer/tender/id${tendersId}`"
          class="btn btn-primary mr-2"
          :disabled="loading"
          :class="{'loading' : loading}"
        >Редактировать</a>
        <b-button
          v-if="$store.state.user.ownerType === 'customer' && data.status === 4"
          type="button"
          variant="success"
          class="mr-2"
          :disabled="loading"
          :class="{'loading' : loading}"
          @click="onAccept()"
        >
          Подтвердить победителя
        </b-button>
        <b-button
          v-if="data.status !== 0 && data.status !== 5 && data.status !== 6 && data.status !== 7"
          type="button"
          variant="primary"
          class="mr-2"
          :disabled="loading"
          :class="{'loading' : loading}"
          @click="onDraft()"
        >
          Вернуть в черновик
        </b-button>
        <b-button
          v-if="$store.state.user.ownerType === 'customer' && data.status === 0"
          type="button"
          variant="success"
          class="mr-2"
          :disabled="loading"
          :class="{'loading' : loading}"
          @click="startTenderModal.show = true"
        >
          Запустить тендер
        </b-button>
        <b-button
          v-if="$store.state.user.ownerType === 'customer' && data.status === 1"
          type="button"
          variant="warning"
          class="mr-2"
          :disabled="loading"
          :class="{'loading' : loading}"
          @click="startTenderModal.show = true"
        >
          Изменить период проведения
        </b-button>
        <b-button
          v-if="$store.state.user.ownerType === 'customer' && data.status === 3"
          type="button"
          variant="warning"
          class="mr-2"
          :disabled="loading"
          :class="{'loading' : loading}"
          @click="startTenderModal.show = true"
        >
          Перезапустить тендер
        </b-button>
        <b-button
          v-if="$store.state.user.ownerType === 'customer' && data.status === 2"
          type="button"
          variant="warning"
          class="mr-2"
          disabled
          :class="{'loading' : loading}"
          @click="confirmEndTender()"
        >
          Завершить тендер
        </b-button>
        <b-button
          v-if="$store.state.user.ownerType === 'customer' && data.status !== 5 && data.status !== 6 && data.status !== 7"
          type="button"
          variant="danger"
          :disabled="loading"
          :class="{'loading' : loading}"
          @click="confirmDeleteTender()"
        >
          Отменить тендер
        </b-button>
      </div>
    </div>

    <b-modal
      v-model="startTenderModal.show"
      :title="'Запуск тендера ' + data.name"
      class="modal-warning"
      cancel-title="Назад"
      ok-variant="success"
      :ok-disabled="startTenderDisabled"
      ok-title="Запустить тендер"
      @ok="customersTenderClearRun()"
    >
      <p>Выберите тип запуска тендера и назначьте время</p>
      <b-form-group
        class="form-group-el-select"
        label="Способ запуска"
      >
        <el-select
          v-model="startTenderModal.method.value"
          class="form-control"
          name="route-way"
          :loading="loading"
        >
          <el-option
            v-for="item in startTenderModal.method.options"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          />
        </el-select>
      </b-form-group>
      <b-form-group
        v-if="startTenderModal.method.value"
        label="Старт тендера"
        label-for="start-tender-modal-start-date"
      >
        <el-date-picker-input
          id="start-tender-modal-start-date"
          v-model="startTenderModal.startDate"
          type="datetime"
          placeholder=""
          format="dd.MM.yyyy HH:mm"
          :picker-options="pickerOptions"
        />
      </b-form-group>
      <b-form-group
        label="Окончание приема ставок"
        label-for="start-tender-modal-end-date"
      >
        <el-date-picker-input
          id="start-tender-modal-end-date"
          v-model="startTenderModal.stopDate"
          type="datetime"
          placeholder=""
          format="dd.MM.yyyy HH:mm"
          :picker-options="pickerOptions"
        />
      </b-form-group>
      <b-form-group>
        <b-form-checkbox
          id="enableOffers"
          v-model="startTenderModal.enableOffers"
          name="enable-offers"
        >
          Возможность подачи встречных предложений
        </b-form-checkbox>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import DimensionsView from '../../components/DimensionsView.vue';
import ElDatePickerInput from '../../components/ElDatePickerInput.vue';
import TenderFlightSchedule from '@/components/TenderFlightSchedule';
import Vue from 'vue';
import {ClientTable, Event} from 'vue-tables-2';
import notifications from '../../components/mixins/notifications';
import {
  routeWaysShortlist,
  transportVehiclesAuctionBodyTypes,
  transportVehiclesLoadingTypes,
  transportVehiclesDimensionsTypes,
  customersTenderRead,
  customersTenderClearRun,
  customersTenderChangeDates,
  customersTenderDraft,
  customersTenderAcceptWinner,
  customersTenderSetWinner,
  customersTenderCancel,
  customersTenderEnd,
} from '../../services/api';
import {
  valueToValueWithVAT,
  valueToValueWithoutVAT,
  numberToRUFormat,
} from '@/components/mixins/helpers';
import moment from 'moment';
import tz from 'moment-timezone';

Vue.use(ClientTable);

const socket = new WebSocket(process.env.VUE_APP_WEBSOCKET_AUCTION_API_URL);
const urlArray = window.location.pathname.split('/');
const tendersId = urlArray[urlArray.length - 1].substr(2);

socket.onopen = () => {
  socket.send(JSON.stringify({
    event: 'authorize',
    data: {
      jwt: localStorage.token,
      tenderId: Number(tendersId),
    },
  }));
};

export default {
  name: 'CustomerTendersView',
  components: {
    ClientTable,
    Event,
    ElDatePickerInput,
    TenderFlightSchedule, DimensionsView,
  },
  mixins: [notifications],
  props: ['customerId', 'tendersId'],
  data() {
    return {
      note: {
				id: null,
				value: null,
			},
      data: {
        status: null,
        additionalWayPointPrice: null,
        alternativeAmounts: false,
        countdown: null,
        name: null,
        startDate: null,
        endDate: null,
        activeOffer: {},
        routeWays: [
          {
            firstRouteWayTimezone: null,
            fullDistance: null,
            timeSummer: null,
            points: [],
            weekCount: null,
            routeCount: null,
          },
        ],
        focusedPlayedRouteWaySelect: null,
        defaultBodyType: [],
        defaultLoadingUnloading: [],
        defaultDimension: null,
        totalPrice: null,
        history: null,
      },
      routesBriefInformation: {
        columns: ['routeWayCode', 'winTemplateSumm', 'routeCount', 'winRouteSumm'],
        options: {
          headings: {
            'routeWayCode': 'Маршрут',
            'winTemplateSumm': 'Цена машрута',
            'routeCount': 'Количество рейсов',
            'winRouteSumm': 'Цена за 1 рейс',
          },
          sortable: ['routeWayCode', 'winTemplateSumm', 'routeCount', 'winRouteSumm'],
          filterable: ['routeWayCode'],
          sortIcon: {base: 'fa', up: 'fa-sort-asc', down: 'fa-sort-desc', is: 'fa-sort'},
          pagination: {
            chunk: 10,
            edge: true,
            nav: 'fixed',
          },
          perPage: 5,
          perPageValues: [5, 10, 25],
          texts: {
            count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
            first: 'Первая',
            last: 'Последняя',
            filter: 'Фильтр:',
            filterPlaceholder: 'Поисковый запрос',
            limit: 'Записи:',
            page: 'Страница:',
            noResults: 'Ни одной ставки/предложения не подано',
            filterBy: 'Фильтр по {column}',
            loading: 'Загрузка...',
            defaultOption: 'Выбор {column}',
            columns: 'Столбцы',
          },
        },
        items: [],
        theme: 'bootstrap4',
      },
      tenderContractorProposals: {
        columns: ['contractor.name', 'sum', 'contractor.customers.0.rating.rating'],
        options: {
          headings: {
            'contractor.name': 'Название компании',
            'sum': 'Предложенная цена',
            'contractor.customers.0.rating.rating': 'Рейтинг',
            'actions': '',
          },
          sortable: ['contractor.name', 'sum', 'contractor.customers.0.rating.rating'],
          filterable: ['contractor.name'],
          sortIcon: {base: 'fa', up: 'fa-sort-asc', down: 'fa-sort-desc', is: 'fa-sort'},
          pagination: {
            chunk: 10,
            edge: true,
            nav: 'fixed',
          },
          perPage: 5,
          perPageValues: [5, 10, 25],
          texts: {
            count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
            first: 'Первая',
            last: 'Последняя',
            filter: 'Фильтр:',
            filterPlaceholder: 'Поисковый запрос',
            limit: 'Записи:',
            page: 'Страница:',
            noResults: 'Ни одной ставки/предложения не подано',
            filterBy: 'Фильтр по {column}',
            loading: 'Загрузка...',
            defaultOption: 'Выбор {column}',
            columns: 'Столбцы',
          },
        },
        items: [],
        theme: 'bootstrap4',
      },
      possibleWinners: {
        columns: ['name', 'sum', 'actions'],
        options: {
          headings: {
            'name': 'Название компании',
            'sum': 'Предложенная цена',
            'actions': '',
          },
          sortable: ['name', 'sum'],
          filterable: ['name'],
          sortIcon: {base: 'fa', up: 'fa-sort-asc', down: 'fa-sort-desc', is: 'fa-sort'},
          pagination: {
            chunk: 10,
            edge: true,
            nav: 'fixed',
          },
          perPage: 5,
          perPageValues: [5, 10, 25],
          texts: {
            count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
            first: 'Первая',
            last: 'Последняя',
            filter: 'Фильтр:',
            filterPlaceholder: 'Поисковый запрос',
            limit: 'Записи:',
            page: 'Страница:',
            noResults: 'Ни одной ставки/предложения не подано',
            filterBy: 'Фильтр по {column}',
            loading: 'Загрузка...',
            defaultOption: 'Выбор {column}',
            columns: 'Столбцы',
          },
        },
        items: [],
        theme: 'bootstrap4',
      },
      form: {
        size: {
          options: [],
        },
        loadingUnloading: {
          options: [],
        },
        bodyType: {
          options: [],
        },
        organizations: {
          value: null,
          options: [],
        },
        routeWays: [
          {
            note: {
              id: null,
              value: null,
            },
            playedRouteWay: {
              options: [],
              value: '',
            },
            routeNumber: null,
            routeWayCode: null,
            transportationName: null,
            winTemplateSumm: null,
            routeCount: null,
            winRouteSumm: null,
            routePrice: null,
            routeType: "oneWay",
            stepRate: null,
            autoTariff: true,
            startDate: null,
            stopDate: null,
            department: [],
            weekTemplate: {
              monday: [],
              tuesday: [],
              wednesday: [],
              thursday: [],
              friday: [],
              saturday: [],
              sunday: [],
            },
            size: {
              value: null,
            },
            loadingUnloading: {
              value: [],
            },
            bodyType: {
              value: [],
            },
          },
        ],
        VAT: {
          options: [
            {value: 0, text: 'Без НДС'},
            {value: 1, text: 'С НДС'},
          ],
          value: 0,
        },
      },
      contractors: {
        columns: ['id', 'name', 'email', 'trucksCount', 'customers.0.rating.rating'],
        options: {
          headings: {
            'id': 'ID',
            'name': 'Название',
            'email': 'Email',
            'trucksCount': 'Кол-во автомобилей',
            'customers.0.rating.rating': 'Рейтинг',
          },
          sortable: ['id', 'name', 'email', 'trucksCount', 'customers.0.rating.rating'],
          filterable: [],
          sortIcon: {base: 'fa', up: 'fa-sort-asc', down: 'fa-sort-desc', is: 'fa-sort'},
          pagination: {
            chunk: 10,
            edge: true,
            nav: 'fixed',
          },
          perPage: 5,
          perPageValues: [5, 10, 25],
          texts: {
            count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
            first: 'Первая',
            last: 'Последняя',
            filter: 'Фильтр:',
            filterPlaceholder: 'Поисковый запрос',
            limit: 'Записи:',
            page: 'Страница:',
            noResults: 'Нет совпадающих записей',
            filterBy: 'Фильтр по {column}',
            loading: 'Загрузка...',
            defaultOption: 'Выбор {column}',
            columns: 'Столбцы',
          },
        },
        items: [],
        theme: 'bootstrap4',
      },
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      startTenderModal: {
        show: false,
        method: {
          options: [
            {value: 0, text: 'Запустить сейчас'},
            {value: 1, text: 'Запустить как отложенный'},
          ],
          value: 0,
        },
        startDate: '',
        stopDate: '',
        enableOffers: true,
      },
      loading: false,
      useVuex: false,
      template: 'default',
    };
  },
  computed: {
    startTenderDisabled() {
      if (this.startTenderModal.method.value) {
        return !(this.startTenderModal.startDate && this.startTenderModal.stopDate);
      } else {
        return !this.startTenderModal.stopDate;
      }
    },
    formStrRun() {
      const calculationFormData = {};
      if (this.startTenderModal.startDate && this.startTenderModal.method.value) {
        calculationFormData.start_date = moment(this.startTenderModal.startDate).format();
      }
      if (this.startTenderModal.stopDate) {
        calculationFormData.stop_date = moment(this.startTenderModal.stopDate).format();
      }
      calculationFormData.enable_offers = this.startTenderModal.enableOffers;
      return calculationFormData;
    },
  },
  sockets: {
    connect: function() {
      console.log('socket connected');
    },
    customEmit: function(data) {
      console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)');
    },
  },
  watch: {
    '$route'() {
      this.customersTenderRead();
    },
  },
  created() {
    socket.onmessage = (message) => {
      try {
        const data = JSON.parse(message.data);

        this.data.status = data.status;
        this.possibleWinners.items = [];
        this.form.routeWays = [
          {
            id: null,
            note: {
              id: null,
              value: null,
            },
            playedRouteWay: {
              options: [],
              value: '',
            },
            routeWayCode: null,
            routePrice: null,
            stepRate: 500,
            startDate: null,
            stopDate: null,
            department: [],
            weekTemplate: {
              monday: [],
              tuesday: [],
              wednesday: [],
              thursday: [],
              friday: [],
              saturday: [],
              sunday: [],
            },
            size: {
              value: null,
            },
            loadingUnloading: {
              value: [],
            },
            bodyType: {
              value: [],
            },
          },
        ];
        if (data.possibleWinners && data.possibleWinners.length > 0) {
          this.possibleWinners.items = data.possibleWinners;
        }
        this.tenderContractorProposals.items = [];
        if (data.tenderContractorProposals.length > 0) {
          data.tenderContractorProposals.forEach((offer) => {
            if (offer.round === data.round) {
              this.tenderContractorProposals.items.unshift(offer);
              if (data.status === 4 || data.status === 6 || data.status === 7) {
                if (offer.winner) {
                  this.data.activeOffer = offer;
                }
              }
            }
          });
          this.tenderContractorProposals.items.sort((a, b) => b.id - a.id);
        }
        if (data.name) {
          this.data.name = data.name;
        }
        
        if (data.additionalWayPointPrice) {
          this.data.additionalWayPointPrice = data.additionalWayPointPrice;
        }

        if (data.organization) {
          this.form.organizations.value = data.organization.name;
        }
        if (data.startDate) {
          this.data.startDate = data.startDate;
        }
        if (data.factEndDate) {
          this.data.endDate = data.factEndDate;
        } else if (data.stopDate) {
          if (this.data.endDate !== data.stopDate) {
            this.data.endDate = data.stopDate;

            let eventTime;
            if (data.status === 1) {
              eventTime = moment(data.startDate).format('x');
            } else {
              eventTime = moment(data.stopDate).format('x');
            }
            const currentTime = moment().format('x');
            const diffTime = eventTime - currentTime;
            let duration = moment.duration(diffTime);
            const interval = 1000;

            setInterval(() => {
              duration = moment.duration(duration - interval, 'milliseconds');
              if (duration.days() === 0) {
                this.data.countdown = duration.hours() + ' ч. ' + duration.minutes() + ' м. ' + duration.seconds() + ' сек.';
              } else {
                this.data.countdown = duration.days() + ' д. ' + duration.hours() + ' ч. ' + duration.minutes() + ' м. ' + duration.seconds() + ' сек.';
              }
            }, interval);
          }
        }
        if (data.routeTemplates.length > 0) {
          data.routeTemplates.forEach((routeTemplate, index) => {
            if (index) {
              this.addRouteWay();
            }
            Vue.set(this.form.routeWays[index], 'autoTariff', routeTemplate.autoTariff);

            if (routeTemplate.routeType) {
								Vue.set(
									this.form.routeWays[index],
									"routeType",
									routeTemplate.routeType
								);
							} else {
                Vue.set(
									this.form.routeWays[index],
									"routeType",
									"oneWay"
								);
              }

            if (routeTemplate.routeWay) {
              Vue.set(
						this.form.routeWays[index],
						"note",
						{id: null,
						value: null}
					)
              this.form.routeWays[index].playedRouteWay.value = routeTemplate.routeWay.id;
              this.form.routeWays[index].routeNumber = index;
              if (routeTemplate.routeWay.code) {
                this.form.routeWays[index].routeWayCode = routeTemplate.routeWay.code;
              }
              if (routeTemplate.winTemplateSumm) {
                this.form.routeWays[index].winTemplateSumm = routeTemplate.winTemplateSumm;
              }
              if (routeTemplate.routeCount) {
                this.form.routeWays[index].routeCount = routeTemplate.routeCount;
              }
              if (routeTemplate.winRouteSumm) {
                this.form.routeWays[index].winRouteSumm = routeTemplate.winRouteSumm;
              }
              if (routeTemplate.transportation) {
                this.form.routeWays[index].transportationName = routeTemplate.transportation.name;
              }
              this.form.routeWays[index].playedRouteWay.options = this.form.routeWays[index].playedRouteWay.options.filter((x) => x.value !== routeTemplate.routeWay.id);
              this.form.routeWays[index].playedRouteWay.options.unshift({
                text: routeTemplate.routeWay.code,
                value: routeTemplate.routeWay.id,
              });
              const departments = [];
              routeTemplate.routeWay.routeWayPoints.forEach((point) => {
                if (point.rowNumber === 1) {
                  this.data.routeWays[index].firstRouteWayTimezone = point.department.timeZone;
                }
                departments.push(point.department.name);
              });
              this.form.routeWays[index].department = departments;
              if (routeTemplate.routeWay.transportationType) {
                this.data.routeWays[index].transportationType = routeTemplate.routeWay.transportationType;
              }
              this.data.routeWays[index].points = routeTemplate.routeWay.routeWayPoints.sort((a, b) => a.rowNumber - b.rowNumber);
              this.data.routeWays[index].fullDistance = routeTemplate.routeWay.routeWayPoints.reduce(function(accumulator, currentValue) {
                return accumulator + Number(currentValue.distance);
              }, 0).toFixed(2);
              this.data.routeWays[index].timeSummer = routeTemplate.routeWay.routeWayPoints.reduce(function(accumulator, currentValue) {
							return (
								accumulator + Number(currentValue.timeSummer)
							);
						}, 0).toFixed(2);
            }
            if (routeTemplate.routeSumm) {
              this.form.routeWays[index].routePrice = routeTemplate.routeSumm;
            }
            if (routeTemplate.step) {
              this.form.routeWays[index].stepRate = routeTemplate.step;
            }
            if (routeTemplate.periodStart) {
              const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
              this.form.routeWays[index].startDate = moment(routeTemplate.periodStart, 'YYYY-MM-DDTHH:mm:ss').tz(clientTimezone).format();
            }
            if (routeTemplate.periodStop) {
              const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
              this.form.routeWays[index].stopDate = moment(routeTemplate.periodStop, 'YYYY-MM-DDTHH:mm:ss').tz(clientTimezone).format();
            }
            if (routeTemplate.routeDateTemplates.length > 0) {
              routeTemplate.routeDateTemplates.forEach((routeDateTemplate) => {
                const routeDateTemplateDateParams = routeDateTemplate.dateTemplate.split(' ');
                this.form.routeWays[index].weekTemplate[routeDateTemplateDateParams[0]].push({
                  weekday: routeDateTemplateDateParams[0],
                  time: routeDateTemplateDateParams[routeDateTemplateDateParams.length - 1],
                });
              });
            }
            this.form.routeWays[index].dimensions = {
              id: routeTemplate.routeWay.id,
              dimension: routeTemplate.dimension,
              optionalDimensions: routeTemplate.optionalDimensions,
            };
            if (routeTemplate.loadingTypes.length > 0) {
              this.form.routeWays[index].loadingUnloading.value = routeTemplate.loadingTypes.map((item) => item.id);
            }
            if (routeTemplate.bodyTypes) {
              this.form.routeWays[index].bodyType.value = routeTemplate.bodyTypes.map((item) => item.id);
            }
            this.tenderComputing(index);
          });
        }
        if (data.summ) {
          this.data.totalPrice = data.summ;
        }
        if (data.withNDS) {
          this.form.VAT.value = 1;
        } else {
          this.form.VAT.value = 0;
        }
        if (data.possibleParticipants.length > 0) {
          this.contractors.items = data.possibleParticipants;
        }
        if (data.tenderHistoryMessages) {
          this.data.history = data.tenderHistoryMessages.sort((a, b) => b.id - a.id);
        }
      } catch (e) {
        console.log(e);
      }
    };
    socket.onclose = (event) => {
      console.log('onclose');
      console.log(event);
    };
    socket.onerror = (error) => {
      console.log('onerror');
      console.log('Ошибка ' + error.message);
    };
  },
  async mounted() {
    this.transportVehiclesAuctionBodyTypes();
    this.transportVehiclesLoadingTypes();
    // this.transportVehiclesDimensionsTypes();
    await this.customersTenderRead();
  },
  methods: {
    formatDatetime(date) {
      return date ? moment(date).format('DD.MM.YYYY HH:mm') : '';
    },
    alternativeAmountValue(vat, value) {
      if (value) {
        if (!vat) {
          return '(' + this.numberRUFormat(valueToValueWithVAT(value)) + ' руб. С НДС)';
        } else {
          return '(' + this.numberRUFormat(valueToValueWithoutVAT(value)) + ' руб. Без НДС)';
        }
      } else {
        return '';
      }
    },
    numberRUFormat(value) {
      return numberToRUFormat(value);
    },
    getFormData: function(object) {
      const str = [];
      for (const p in object) {
        if (object.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(object[p]));
        }
      }
      return str.join('&');
    },
    tenderComputing(index) {
      if (this.form.routeWays[index].startDate && this.form.routeWays[index].stopDate) {
        const start = moment(this.form.routeWays[index].startDate);
        const stop = moment(this.form.routeWays[index].stopDate);
        const days = stop.diff(start, 'days') + 1;

        const startWeekday = moment(this.form.routeWays[index].startDate).format('e');
        const stopWeekday = moment(this.form.routeWays[index].stopDate).format('e');

        let extraDaysCount = 0;
        if (startWeekday === '0') {
          extraDaysCount = 1 + Number(stopWeekday);
        } else if (startWeekday === '1') {
          extraDaysCount = Number(stopWeekday);
        } else {
          extraDaysCount = 8 - Number(startWeekday) + Number(stopWeekday);
        }
        const fullWeeks = Math.floor(days/7);

        let missingDaysCount = 0;
        if (startWeekday === '0') {
          missingDaysCount = 6;
        } else {
          missingDaysCount = Number(startWeekday) - 1;
        }
        if (stopWeekday !== '0') {
          missingDaysCount = missingDaysCount + 7 - Number(stopWeekday);
        }
        const allWeeks = (days + missingDaysCount)/7;
        this.data.routeWays[index].weekCount = allWeeks;

        let allRoutes = 0;
        const weekdaysCounters = [fullWeeks, fullWeeks, fullWeeks, fullWeeks, fullWeeks, fullWeeks, fullWeeks];
        if (allWeeks > fullWeeks) {
          if ((Number(stopWeekday) + 1 === Number(startWeekday)) || (Number(stopWeekday) === 6 && Number(startWeekday) === 0)) {
            // nothing
          } else if (startWeekday <= stopWeekday) {
            for (let i = startWeekday; i <= stopWeekday; i++) {
              weekdaysCounters[i]++;
            }
          } else {
            if (Number(stopWeekday) > 0) {
              for (let i = stopWeekday; Number(i) > 0; i--) {
                weekdaysCounters[i]++;
              }
            }
            for (let i = startWeekday; Number(i) <= 6; i++) {
              weekdaysCounters[i]++;
            }
            weekdaysCounters[0]++;
          }
        }
        allRoutes =
                this.form.routeWays[index].weekTemplate.monday.length * weekdaysCounters[1] +
                this.form.routeWays[index].weekTemplate.tuesday.length * weekdaysCounters[2] +
                this.form.routeWays[index].weekTemplate.wednesday.length * weekdaysCounters[3] +
                this.form.routeWays[index].weekTemplate.thursday.length * weekdaysCounters[4] +
                this.form.routeWays[index].weekTemplate.friday.length * weekdaysCounters[5] +
                this.form.routeWays[index].weekTemplate.saturday.length * weekdaysCounters[6] +
                this.form.routeWays[index].weekTemplate.sunday.length * weekdaysCounters[0];
        this.data.routeWays[index].routeCount = allRoutes;
      } else {
        this.data.routeWays[index].weekCount = null;
        this.data.routeWays[index].routeCount = null;
      }
    },
    async customersTenderRead() {
      this.loading = true;
      const response = await customersTenderRead(this.customerId, this.tendersId);
      if (response && response.status === 200) {
        this.data.status = response.data.status;
        this.possibleWinners.items = [];
        this.form.routeWays = [
          {
            id: null,
            note: {
              id: null,
              value: null,
            },
            playedRouteWay: {
              options: [],
              value: '',
            },
            routeWayCode: null,
            routePrice: null,
            stepRate: 500,
            startDate: null,
            stopDate: null,
            department: [],
            weekTemplate: {
              monday: [],
              tuesday: [],
              wednesday: [],
              thursday: [],
              friday: [],
              saturday: [],
              sunday: [],
            },
            size: {
              value: null,
            },
            loadingUnloading: {
              value: [],
            },
            bodyType: {
              value: [],
            },
          },
        ];
        if (response.data.possibleWinners && response.data.possibleWinners.length > 0) {
          this.possibleWinners.items = response.data.possibleWinners;
        }
        this.tenderContractorProposals.items = [];
        if (response.data.tenderContractorProposals.length > 0) {
          response.data.tenderContractorProposals.forEach((offer) => {
            if (offer.round === response.data.round) {
              this.tenderContractorProposals.items.unshift(offer);
              if (response.data.status === 4 || response.data.status === 6 || response.data.status === 7) {
                if (offer.winner) {
                  this.data.activeOffer = offer;
                }
              }
            }
          });
          this.tenderContractorProposals.items.sort((a, b) => b.id - a.id);
        }
        if (response.data.name) {
          this.data.name = response.data.name;
        }

        if (response.data.additionalWayPointPrice) {
          this.data.additionalWayPointPrice = response.data.additionalWayPointPrice; 
        }

        if (response.data.organization) {
          this.form.organizations.value = response.data.organization.name;
        }
        if (response.data.startDate) {
          this.data.startDate = response.data.startDate;
        }
        if (response.data.factEndDate) {
          this.data.endDate = response.data.factEndDate;
        } else if (response.data.stopDate) {
          if (this.data.endDate !== response.data.stopDate) {
            this.data.endDate = response.data.stopDate;

            let eventTime;
            if (response.data.status === 1) {
              eventTime = moment(response.data.startDate).format('x');
            } else {
              eventTime = moment(response.data.stopDate).format('x');
            }
            const currentTime = moment().format('x');
            const diffTime = eventTime - currentTime;
            let duration = moment.duration(diffTime);
            const interval = 1000;

            setInterval(() => {
              duration = moment.duration(duration - interval, 'milliseconds');
              if (duration.days() === 0) {
                this.data.countdown = duration.hours() + ' ч. ' + duration.minutes() + ' м. ' + duration.seconds() + ' сек.';
              } else {
                this.data.countdown = duration.days() + ' д. ' + duration.hours() + ' ч. ' + duration.minutes() + ' м. ' + duration.seconds() + ' сек.';
              }
            }, interval);
          }
        }
        if (response.data.routeTemplates.length > 0) {
          response.data.routeTemplates.forEach((routeTemplate, index) => {
            if (index) {
              this.addRouteWay();
            }
            Vue.set(this.form.routeWays[index], 'autoTariff', routeTemplate.autoTariff);

            if (routeTemplate.routeType) {
								Vue.set(
									this.form.routeWays[index],
									"routeType",
									routeTemplate.routeType
								);
							} else {
                Vue.set(
									this.form.routeWays[index],
									"routeType",
									"oneWay"
								);
              }

            if (routeTemplate.routeWay) {
              Vue.set(
						this.form.routeWays[index],
						"note",
						{id: null,
						value: null}
					)
              this.form.routeWays[index].playedRouteWay.value = routeTemplate.routeWay.id;
              this.form.routeWays[index].routeNumber = index;
              if (routeTemplate.routeWay.code) {
                this.form.routeWays[index].routeWayCode = routeTemplate.routeWay.code;
              }
              if (routeTemplate.winTemplateSumm) {
                this.form.routeWays[index].winTemplateSumm = routeTemplate.winTemplateSumm;
              }
              if (routeTemplate.routeCount) {
                this.form.routeWays[index].routeCount = routeTemplate.routeCount;
              }
              if (routeTemplate.winRouteSumm) {
                this.form.routeWays[index].winRouteSumm = routeTemplate.winRouteSumm;
              }
              if (routeTemplate.transportation) {
                this.form.routeWays[index].transportationName = routeTemplate.transportation.name;
              }
              this.form.routeWays[index].playedRouteWay.options = this.form.routeWays[index].playedRouteWay.options.filter((x) => x.value !== routeTemplate.routeWay.id);
              this.form.routeWays[index].playedRouteWay.options.unshift({
                text: routeTemplate.routeWay.code,
                value: routeTemplate.routeWay.id,
              });
              const departments = [];
              routeTemplate.routeWay.routeWayPoints.forEach((point) => {
                if (point.rowNumber === 1) {
                  this.data.routeWays[index].firstRouteWayTimezone = point.department.timeZone;
                }
                departments.push(point.department.name);
              });
              this.form.routeWays[index].department = departments;
              this.data.routeWays[index].transportationType = routeTemplate.routeWay.transportationType;
              this.data.routeWays[index].points = routeTemplate.routeWay.routeWayPoints.sort((a, b) => a.rowNumber - b.rowNumber);
              this.data.routeWays[index].fullDistance = routeTemplate.routeWay.routeWayPoints.reduce(function(accumulator, currentValue) {
                return accumulator + Number(currentValue.distance);
              }, 0).toFixed(2);
              this.data.routeWays[index].timeSummer = routeTemplate.routeWay.routeWayPoints.reduce(function(accumulator, currentValue) {
							return (
								accumulator + Number(currentValue.timeSummer)
							);
						}, 0).toFixed(2);
            }
            if (routeTemplate.routeSumm) {
              this.form.routeWays[index].routePrice = routeTemplate.routeSumm;
            }
            if (routeTemplate.step) {
              this.form.routeWays[index].stepRate = routeTemplate.step;
            }
            if (routeTemplate.periodStart) {
              const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
              this.form.routeWays[index].startDate = moment(routeTemplate.periodStart, 'YYYY-MM-DDTHH:mm:ss').tz(clientTimezone).format();
            }
            if (routeTemplate.periodStop) {
              const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
              this.form.routeWays[index].stopDate = moment(routeTemplate.periodStop, 'YYYY-MM-DDTHH:mm:ss').tz(clientTimezone).format();
            }
            if (routeTemplate.routeDateTemplates.length > 0) {
              routeTemplate.routeDateTemplates.forEach((routeDateTemplate) => {
                const routeDateTemplateDateParams = routeDateTemplate.dateTemplate.split(' ');
                this.form.routeWays[index].weekTemplate[routeDateTemplateDateParams[0]].push({
                  weekday: routeDateTemplateDateParams[0],
                  time: routeDateTemplateDateParams[routeDateTemplateDateParams.length - 1],
                });
              });
            }
            this.form.routeWays[index].dimensions = {
              id: routeTemplate.routeWay.id,
              dimension: routeTemplate.dimension,
              optionalDimensions: routeTemplate.optionalDimensions,
            };
            if (routeTemplate.loadingTypes.length > 0) {
              this.form.routeWays[index].loadingUnloading.value = routeTemplate.loadingTypes.map((item) => item.id);
            }
            if (routeTemplate.bodyTypes) {
              this.form.routeWays[index].bodyType.value = routeTemplate.bodyTypes.map((item) => item.id);
            }
            this.tenderComputing(index);
          });
        }
        if (response.data.summ) {
          this.data.totalPrice = response.data.summ;
        }
        if (response.data.withNDS) {
          this.form.VAT.value = 1;
        } else {
          this.form.VAT.value = 0;
        }
        if (response.data.possibleParticipants.length > 0) {
          this.contractors.items = response.data.possibleParticipants;
        }
        if (response.data.tenderHistoryMessages) {
          this.data.history = response.data.tenderHistoryMessages.sort((a, b) => b.id - a.id);
        }
        if (response.data.routeTemplates) {
            response.data.routeTemplates.forEach((route, index) => {
				if (route.comment) {
					Vue.set(this.form.routeWays[index], "note", {value: route.comment, id: route.id});
				}
            });       
        }
      }
      this.loading = false;
    },
    async routeWaysShortlist(name, index) {
      if (typeof(index) !== 'number') {
        index = this.data.focusedPlayedRouteWaySelect;
      }
      this.loading = true;
      const response = await routeWaysShortlist({
        query: name,
        limit: 100,
      });
      if (response && response.status === 200) {
        this.form.routeWays[index].playedRouteWay.options = response.data.items.map((item) => {
          return {value: item.id, text: item.code};
        });
      }
      this.form.routeWays[index].dimensions = {
        id: null,
        dimension: null,
        optionalDimensions: [],
      };
      this.loading = false;
    },
    // async transportVehiclesDimensionsTypes() {
    //   this.loading = true;
    //   const response = await transportVehiclesDimensionsTypes({limit: 100});
    //   if (response && response.status === 200) {
    //     this.form.size.options = response.data.items.map((item) => {
    //       if (item.name === '20 т(80-93 м3)') {
    //         this.data.defaultDimension = item.id;
    //       }
    //       return {value: item.id, text: item.name};
    //     });
    //   }
    //   this.loading = false;
    // },
    async transportVehiclesLoadingTypes() {
      this.loading = true;
      const response = await transportVehiclesLoadingTypes({limit: 100});
      if (response && response.status === 200) {
        this.form.loadingUnloading.options = response.data.items.map((item) => {
          this.data.defaultLoadingUnloading.push(item.id);
          return {value: item.id, text: item.name};
        });
      }
      this.loading = false;
    },
    async transportVehiclesAuctionBodyTypes() {
      this.loading = true;
      const response = await transportVehiclesAuctionBodyTypes({limit: 100});
      if (response && response.status === 200) {
        this.form.bodyType.options = response.data.items.map((item) => {
          if (item.name === 'Тент с усиленной обрешеткой') {
            this.data.defaultBodyType.push(item.id);
          }
          return {value: item.id, text: item.name};
        });
      }
      this.loading = false;
    },
    deleteChosenContractor(contractorId) {
      this.contractors.items = this.contractors.items.filter((item) => item.id !== contractorId);
    },
    addRouteWay() {
      this.form.routeWays.push({
        playedRouteWay: {
          options: [],
          value: '',
        },
        note: {
          id: null,
          value: null,
        },
        routeNumber: null,
        routeWayCode: null,
        transportationName: null,
        winTemplateSumm: null,
        routeCount: null,
        winRouteSumm: null,
        routePrice: null,
        routeType: "oneWay",
        stepRate: null,
        autoTariff: true,
        startDate: null,
        stopDate: null,
        department: [],
        weekTemplate: {
          monday: [],
          tuesday: [],
          wednesday: [],
          thursday: [],
          friday: [],
          saturday: [],
          sunday: [],
        },
        size: {
          options: [],
          value: null,
        },
        loadingUnloading: {
          options: [],
          value: [],
        },
        bodyType: {
          options: [],
          value: [],
        },
      });
      this.data.routeWays.push({
        firstRouteWayTimezone: null,
        fullDistance: null,
        timeSummer: null,
        points: [],
        weekCount: null,
        routeCount: null,
      });
    },
    async customersTenderClearRun() {
      this.loading = true;
      if (this.data.status === 0) {
        const response = await customersTenderClearRun(this.customerId, this.tendersId, this.getFormData(this.formStrRun));
        if (response && response.status === 200) {
          this.showSuccess('Тендер подготовлен к запуску');
          this.customersTenderRead();
        }
      } else if (this.data.status === 1) {
        const response = await customersTenderChangeDates(this.customerId, this.tendersId, this.formStrRun);
        if (response && response.status === 200) {
          this.showSuccess('Даты запуска тендера успешно обновлены');
          this.customersTenderRead();
        }
      } else if (this.data.status === 3) {
        const response = await customersTenderDraft(this.customerId, this.tendersId);
        if (response && response.status === 200) {
          this.loading = true;
          const response = await customersTenderClearRun(this.customerId, this.tendersId, this.getFormData(this.formStrRun));
          if (response && response.status === 200) {
            this.showSuccess('Тендер подготовлен к запуску');
            this.customersTenderRead();
          }
          this.loading = false;
        }
      }
      this.loading = false;
    },
    async onDraft() {
      this.loading = true;
      const response = await customersTenderDraft(this.customerId, this.tendersId);
      if (response && response.status === 200) {
        this.showSuccess('Тендер переведен в черновик');
        this.customersTenderRead();
      }
      this.loading = false;
    },
    async onAccept() {
      this.loading = true;
      const response = await customersTenderAcceptWinner(this.customerId, this.tendersId);
      if (response && response.status === 200) {
        this.showSuccess('Победитель подтвежден');
        this.customersTenderRead();
      }
      this.loading = false;
    },
    confirmDeleteTender() {
      this.$bvModal
        .msgBoxConfirm('Вы уверены что хотите удалить тендер?', {
          okTitle: 'Да',
          cancelTitle: 'Назад',
        })
        .then((confirm) => {
          if (confirm) {
            this.onCancel();
          }
        });
    },
    async onCancel() {
      this.loading = true;
      const response = await customersTenderCancel(this.customerId, this.tendersId);
      if (response && response.status === 200) {
        this.showSuccess('Тендер отменен');
        this.$router.push({path: '/tenders/customer'});
      }
      this.loading = false;
    },
    async setWinner(contractorId) {
      this.loading = true;
      const response = await customersTenderSetWinner(this.customerId, this.tendersId, contractorId);
      if (response && response.status === 200) {
        this.showSuccess('Победитель назначен');
        this.customersTenderRead();
      }
      this.loading = false;
    },
    confirmEndTender() {
      this.$bvModal
        .msgBoxConfirm('Вы уверены что хотите завешить тендер?', {
          okTitle: 'Да',
          cancelTitle: 'Назад',
        })
        .then((confirm) => {
          if (confirm) {
            this.onEnd();
          }
        });
    },
    async onEnd() {
      this.loading = true;
      const response = await customersTenderEnd(this.customerId, this.tendersId);
      if (response && response.status === 200) {
        this.showSuccess('Тендер завершен');
        this.customersTenderRead();
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
  .tender-info {
    overflow: hidden;
    background: #f3f4f5;
    border-radius: 5px;

    &__header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-left: -15px;
      margin-right: -15px;
      padding: 5px 10px;
      border-bottom: 1px solid #c8ced3;

      &-item {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    &__body {

      &-title {
        margin-top: 24px;
        margin-bottom: 24px;
        font-size: 36px;
        font-weight: 700;
        text-align: center;
      }
      &-subtitle {
        margin-top: 24px;
        margin-bottom: 24px;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
      }
      &-dates {
        display: flex;
        max-width: 480px;
        margin: 24px auto;
      }
      &-start-date {
        flex: 0 0 50%;
        max-width: 50%;
        font-size: 14px;
      }
      &-end-date {
        flex: 0 0 50%;
        max-width: 50%;
        font-size: 14px;
        text-align: right;
      }
      &-buttons {
        display: flex;
        justify-content: center;
        margin-top: 60px;
      }
    }
    &__previous-bet {
      position: relative;
      min-height: 100%;
      padding: 5px 10px;

      &:after {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(50%);
        display: block;
        width: 1px;
        height: 100%;
        background-color: #c8ced3;
        content: '';
      }

      &-title {
        font-weight: 700;
        font-size: 14px;
      }
      &-list {
        max-height: 271px;
        overflow: auto;
        background-color: white;
        border: 1px solid #c8ced3;
      }
      &-item {
        padding: 2px 5px;
        border-bottom: 1px solid #c8ced3;

        &:last-child {
          border-bottom: 0;
        }

        &-header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
        }
        &-date {
          font-weight: 700;
          font-size: 11px;
        }
        &-type {
          font-size: 11px;
        }
        &-info {
          position: relative;
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          margin-bottom: 10px;

          &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 28px;
            height: 2px;
            background-color: #20a8d8;
            content: '';
          }
        }
        &-contractor {
          flex: 0 0 50%;
          max-width: 50%;
          padding-right: 15px;
          font-weight: 700;
          font-size: 13px;
        }
        &-price {
          flex: 0 0 50%;
          max-width: 50%;
          padding-left: 15px;
          font-weight: 700;
          font-size: 14px;
        }
        &-buttons {
          display: flex;
          justify-content: center;
          margin-top: 10px;
        }
      }
    }
    &__last-bet {
      position: relative;

      &-title {
        margin-bottom: 24px;
        font-weight: normal;
        font-size: 28px;
        line-height: 34px;
        color: #23282C;
      }
      &-desc {
        margin-bottom: 18px;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        color: #768192;
      }
      &-time {
        position: absolute;
        top: 5px;
        right: 10px;
        margin-top: 0;
        margin-bottom: 0;
        font-weight: 700;
        line-height: 27px;
        text-align: right;
      }
      &-current-bid {
        display: flex;
        align-items: flex-end;
        margin-top: 16px;
        margin-bottom: 16px;

        &-title {
          min-width: 200px;
          font-weight: 700;
          font-size: 14px;
        }
        &-value {
          font-weight: 700;
          font-size: 28px;
          line-height: normal;
          color: #4dbd74;

          &_no-winner {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            color: #c8ced3;
          }

          &-vat {
            font-size: 12px;
            color: #636f83;
          }
        }
      }
      &-current-contractor {
        display: flex;
        align-items: flex-end;
        margin-top: 16px;
        margin-bottom: 16px;

        &-title {
          min-width: 200px;
          font-weight: 700;
          font-size: 14px;
        }
        &-value {
          font-style: italic;
          font-weight: 700;
          font-size: 18px;
          line-height: normal;
          color: #20a8d8;

          &_no-winner {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            color: #c8ced3;
          }
        }
      }
      &-current-time {
        display: flex;
        align-items: flex-end;
        margin-top: 16px;
        margin-bottom: 16px;

        &-title {
          min-width: 200px;
          font-weight: 700;
          font-size: 14px;
        }
        &-value {
          font-weight: 700;
          font-size: 14px;
          color: #20a8d8;

          &_no-winner {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            color: #c8ced3;
          }
        }
      }
      &-buttons {
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }
    }
  }

  .custom-fieldset {

    &:nth-last-child(2) {

      .route-and-flight-details__add-route-way {
        display: initial;
      }
    }
  }

  .history-tender {
    &__list {
      list-style: inside none;
      overflow: auto;
      height: 444px;
      padding: 10px 5px;
      margin-bottom: 0;
      background-color: #2b2b2b;

      &-item {
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }

        &-date {
          color: #797979;
        }
        &-changes {
          padding-left: 0;
          list-style-position: inside;
        }
      }
    }
  }

  .VueTables__table {
    margin-bottom: 2rem;
  }

  .route-and-flight-details {
    position: relative;
    margin-top: 24px;

    &:before {
      position: absolute;
      top: 0;
      left: 66.66667%;
      transform: translateX(-50%);
      display: block;
      width: 1px;
      height: 100%;
      background-color: #c8ced3;
      content: '';

      @media screen and (max-width: 1199px){
        display: none;
      }
    }

    &__dates {

      @media screen and (max-width: 1199px){
        position: relative;
        margin-top: 40px;
        padding-top: 20px;

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          border: 1px solid #c8ced3;
          content: '';
        }
      }
    }
    &__title {
      margin-top: 0;
      margin-bottom: 8px;
    }
    &__route-container {
      position: relative;
      padding-bottom: 37px;

      &:last-child {
        padding-bottom: 0;
      }
    }
    &__date {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 32px;

      &-title {
        flex: 0 0 100%;
        max-width: 100%;
      }
      &-number {
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 15px;
      }
      &-time {
        flex: 0 0 50%;
        max-width: 50%;
        padding-left: 15px;
      }
    }
    &__transport {
      padding-bottom: 37px;
    }
    &__form-group {

      &:last-child {
        margin-bottom: 0;
      }

      .col-form-label {
        font-weight: bold;
      }
    }
    &__buttons {
      position: absolute;
      bottom: 0;
      display: block;
      width: 100%;
    }
    &__add-route-way {
      display: none;
    }
  }

  .route-tender {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 480px;
    height: 100%;

    &:before {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 3px;
      height: 100%;
      background-color: #c8ced3;
      content: '';
    }

    &__item {
      position: relative;
      z-index: 1;
      max-width: 480px;
      padding: 10px 6px;
      margin-top: 18px;
      background: #f3f4f5;
      border: 1px solid #c8ced3;

      &:first-child {
        margin-top: 0;

        &:before {
          display: none;
        }
      }

      &:before {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 10px solid transparent;
        border-top: 10px solid #c8ced3;
        content: '';
      }

      &-header {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 767px){
          flex-wrap: wrap;
        }
      }
      &-number {
        padding-right: 20px;
        white-space: nowrap;
      }
      &-type {
        width: auto;
        min-width: 136px;
        padding: 0 5px;
      }
      &-distance {
        padding-left: 20px;
        white-space: nowrap;
        @media screen and (max-width: 767px){
          width: 100%;
          padding-left: 0;
        }
      }
      &-address {
        margin-top: 12px;
        margin-bottom: 0;
      }
      &-footer {
        padding-top: 28px;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .route-way-week {
    margin-bottom: 16px;

    &__head {
      display: flex;

      &-item {
        display: block;
        flex: 0 0 14.2857%;
        max-width: 14.2857%;
        text-align: center;
        border: 1px solid gray;
        transition: all .33s;

        &.active {
          background-color: #20a8d8;
          border-color: #20a8d8;
          color: white;
        }
      }
    }
    &__body {
      display: flex;

      &-weekday {
        display: block;
        flex: 0 0 14.2857%;
        max-width: 14.2857%;
        padding-left: 2px;
        padding-right: 2px;
      }
      &-item {
        position: relative;
        display: block;
        padding-top: 24px;
        margin-bottom: 0;

        &:before {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          display: block;
          width: 3px;
          height: 24px;
          background-color: rgba(#20a8d8, .25);
          content: '';
        }
      }
      &-time {
        height: 20px;
        padding: 2px;
        font-size: 16px;
        line-height: 1;
        text-align: center;
        -moz-appearance: textfield;
        appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
          display: none;

          &:hover {
            display: none;
          }
        }

        ::-ms-clear,
        ::-ms-reveal {
          display: none;
          width : 0;
          height: 0;
        }

        &:focus,
        &:hover {
          -moz-appearance: number-input;
          appearance: number-input;
        }
      }
      &-remove {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        padding: 0;
        background-color: transparent;
        border: 0;
        font: normal normal normal 20px/1 FontAwesome;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: red;
        cursor: pointer;
        transition: color 0.33s;

        &:before {
          content: "\f00d";
        }
      }
      &-add {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        margin: 24px auto 0;
        padding: 0;
        background-color: transparent;
        border: 0;
        font: normal normal normal 16px/1 FontAwesome;
        text-align: center;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #20a8d8;
        cursor: pointer;

        &:before {
          position: absolute;
          bottom: 100%;
          left: 50%;
          transform: translateX(-50%);
          display: block;
          width: 3px;
          height: 24px;
          background-color: rgba(#20a8d8, .25);
          content: '';
        }
        &:after {
          margin-top: -2px;
          margin-left: -1.5px;
          content: "\f0fe";
        }

        &:first-child {
          display: none;
        }
      }
    }
  }

  .terms-and-participants {

    &__contractors {
      padding: 1rem;
    }
    &__start-price {
      position: relative;
      height: 100%;
      padding: 1rem;

      &:before {
        position: absolute;
        top: 0;
        left: -.5px;
        display: block;
        width: 1px;
        height: 100%;
        background-color: #c8ced3;
        content: '';
        @media screen and (max-width: 991px){
          left: 0;
          width: 100%;
          height: 1px;
        }
      }
    }
    &__tender-duration {
      position: relative;
      margin-top: 32px;
      padding-top: 20px;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        border: 1px dashed #c8ced3;
        content: '';
      }
    }
  }


  .tab-content {

    .tab-pane.terms-and-participants {
      padding: 0;
    }
  }


  /* Update tenders styles */
  .tender-page {

    &__title {
      font-size: 40px;
      line-height: 1;
      margin-bottom: 40px;
    }

    .form-group {

      > label,
      > legend {
        font-size: 16px;
        line-height: 21px;
        font-weight: bold;
      }
    }
  }

  .tender-info {
    overflow: hidden;
    padding: 20px 30px;
    background-color: white;
    border-radius: 4px 4px 0 0;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);

    &__body {

      &-title {
        text-align: left;
        font-size: 40px;
        line-height: 1;
        margin-bottom: 40px;

        &-status {
          position: relative;
          top: -7px;
          display: inline-block;
          padding-left: 10px;
          padding-right: 10px;
          margin-left: 10px;
          border-radius: 13px;
          font-size: 14px;
          line-height: 25px;
        }
      }
    }
    &__item {
      margin-bottom: 24px;

      &-title {
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 21px;
        font-weight: bold;
      }
      &-desc {
        margin-top: 12px;
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 21px;
        color: #768192;

        &-note {
          font-size: .75em;
          line-height: 1;
          font-style: italic;
          font-weight: 700;
        }
      }
    }
  }

  .custom-fieldset {
    margin-top: 60px;

    &__title {
      margin-bottom: 16px;
      font-size: 32px;
      line-height: 38px;
      font-weight: normal;
    }
    &__subtitle {
      font-size: 16px;
      line-height: 21px;
      font-weight: bold;
    }
    &__desc {
      margin-top: 16px;
      margin-bottom: 16px;
      font-size: 24px;
      line-height: 28px;
      font-weight: normal;
    }
  }

  .route-and-flight-details {

    &:before {
      display: none;
    }
    &__title {
      margin-bottom: 16px;
      font-size: 28px;
      line-height: 34px;
      font-weight: normal;
    }
    &__route-container {
      padding-bottom: 40px;
    }
  }
  /* Update tenders styles */
</style>

